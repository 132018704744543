
// Libraries
import * as React from 'react'

// Services
import ApiService from "../../../services/api"

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import DuoSection from "../../../components/duoSection"
import AdminVenueViewRow from "../../../components/admin/venues/adminVenueViewRow"
import InputFactory from "../../../components/inputFactory"
import Button from "../../../components/button"

// Context
import NotificationContext from '../../../contexts/notification'
import ArtistService from '../../../services/artist'

class AdminArtistsNewPage extends React.Component {
	state = {
		inputValues: {},
		inputErrors: {},
		isSubmitting: false
	}

	static contextType = NotificationContext

	render() {
		const {inputValues, inputErrors, isSubmitting} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-form nav-blue-half" hideSideNav pageAdminForm>
				<Seo title="New Artist" />
				<DuoSection adminForm>
					<div>
						<h1>New Artist</h1>
					</div>
					<div className="admin-form__wrap">
						<div className="admin-form admin-form--artists">
							<div className="admin-form__form">
								<form onSubmit={this.handleSubmit} encType="multipart/form-data">
									<div className="admin-form__field-group">
										<h2>Information</h2>
										<AdminVenueViewRow
											label="Name*"
											value={<InputFactory
												type="text"
												name="name"
												value={inputValues?.name}
												error={inputErrors?.name}
												onChange={this.handleInputChange}/>}
										/>
										<AdminVenueViewRow
											label="Description*"
											value={<InputFactory
												type="tinymce"
												name="description"
												value={inputValues?.description}
												error={inputErrors?.description}
												onChange={this.handleInputChange}/>}
										/>
										<AdminVenueViewRow
											label={<>Image*<br/><small>Size: 640px x 780px</small></>}
											value={<InputFactory
												type="file"
												name="image"
												value={inputValues?.image}
												error={inputErrors?.image}
												onChange={this.handleFileChange}/>}
										/>
										<AdminVenueViewRow
											checkbox
											label=""
											value={<InputFactory
												type="checkbox"
												name="is_published"
												label={<>
													Publish Artist's Landing Page?<br/>
													<small>If unchecked, the artist's page will be drafted & not
														published within the website</small>
												</>}
												checked={inputValues?.is_published}
												value={inputValues?.is_published}
												error={inputErrors?.is_published}
												onChange={this.handleFileChange}
												checkboxEndeavour/>}
										/>
									</div>

									<div className="admin-form__submit-wrap">
										<Button type="button" to="/admin/artists" colorEndeavour hollow>Cancel</Button>
										<Button type="submit" isLoading={isSubmitting} colorEndeavour>Add Artist</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</DuoSection>
			</LayoutAdmin>
		</PrivateRoute>
	}

	handleInputChange = (value, name, scope) => {
		const {inputValues} = this.state

		if (value !== undefined) {
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		this.setState({ inputValues })
	}

	handleFileChange = (value, name) => {
		const {inputValues} = this.state

		inputValues[name] = value
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {inputValues} = this.state
		const apiService = new ApiService()
		const artistService = new ArtistService()
		const {addNotification} = this.context

		const formData = artistService.formData(inputValues)
		formData.set('is_published', inputValues.is_published ? 1 : 0)

		await apiService.post(`artists`, {body: formData}, true)
			.then(async (response) => {
				if (response.success) {
					addNotification('New Artist Created', 'success')
					this.props.navigate('/admin/artists')
				}
				else {
					this.setState({
						inputErrors: response.errors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminArtistsNewPage
