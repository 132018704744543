
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class ArtistService {
	async getOptions(searchString) {
		const queryParams = {
			search: searchString
		}

		return await apiService.get('artists/options', {}, queryParams)
	}

	formData(inputValues) {
		const formData = new FormData()
		Object.keys(inputValues).forEach(key => {
			formData.set(key, inputValues[key])
		})
		return formData
	}
}
